<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="person">
          <div class="introduction">
            <h3>{{ data.name }}</h3>
            <img :src=data.pic v-if="data.pic!=null && data.pic!=''" class="image-center">
            <br>
            <p class="p-center">{{ data.brief }}
            </p>
          </div>
        </div>
        <div style="cursor: default" v-html="data.content"></div>
      </div>
      <div slot="rightDiv">
        <div class="card">
          <div class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
          <ul>
            <li v-if="lastArticle" @click="click(id-1)">
              <img v-if="lastArticle.pic!=null&&lastArticle.pic!=''&&nextArticle!='null'" :src="lastArticle.pic">
              <p><span>{{ lastArticle.name }}</span></p>
            </li>
            <li>
              <img v-if="data.pic!=null&&data.pic!=''&&data.pic!='null'" :src="data.pic">
              <p><span>{{ data.name }}</span></p>
            </li>
            <li v-if="nextArticle"  @click="click(id+1)">
              <img v-if="nextArticle.pic!=null&&nextArticle.pic!=''&&nextArticle!='null'" :src="nextArticle.pic">
              <p><span>{{ nextArticle.name }}</span></p>
            </li>
          </ul>
          <a>查看更多>>></a>
        </div>
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";

export default {
  name: "expertDetails",
  components: {
    leftAndRight
  },
  data() {
    return {
      id: null,
      data: {},
      lastArticle: null,
      nextArticle: null,

    }
  },
  mounted() {
    this.getData(this.$route.query.id)
  },
  watch: {
    // '$route'() {
    //   this.getData(this.$route.query.id)
    // }
  },
  methods: {
    click(id) {
      localStorage.setItem('exportIndex', id)
      this.lastArticle=null
      this.nextArticle=null
      this.data={}
      this.id=null
      this.getData()
    },
    getData() {
      // this.$http.get('/safe/getArticle', {
      //   params: {
      //     id: id
      //   }
      // }).then((res) => {
      //   this.nextArticle = null
      //   this.lastArticle = null
      //
      //   res.data.pic = this.$constContent.imgUrl + res.data.pic
      //   this.data = res.data
      //   console.log(this.data)
      //   this.data.time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", new Date(res.data.time))
      //   if (res.data.lastArticle !== null) {
      //     this.lastArticle = res.data.lastArticle
      //     this.lastArticle.pic = this.$constContent.imgUrl + res.data.lastArticle.pic
      //   }
      //   if (res.data.nextArticle !== null) {
      //     this.nextArticle = res.data.nextArticle
      //     this.nextArticle.pic = this.$constContent.imgUrl + res.data.nextArticle.pic
      //   }
      //
      // })
      let list = JSON.parse(localStorage.getItem('exportList'))
      let index = Number(localStorage.getItem('exportIndex'))
      this.id = index
      this.data = list[index]
      if (this.pic != null && this.data.pic != '' && this.data.pic != 'null') {
        this.data.pic = this.$constContent.imgUrl + this.data.pic
      }

      this.lastArticle = list[index - 1]

      this.nextArticle = list[index + 1]
      // if (this.nextArticle != null && this.nextArticle.pic != '' && this.nextArticle.pic != 'null') {
      //   this.nextArticle.pic = this.$constContent.imgUrl + this.nextArticle.pic
      // }
      // if (this.lastArticle != null && this.lastArticle.pic != '' && this.lastArticle.pic != 'null') {
      //   this.lastArticle.pic = this.$constContent.imgUrl + this.lastArticle.pic
      // }
      console.log(this.lastArticle)
    }
  }
}
</script>

<style scoped>
.person {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.person .introduction {
  width: 100%;
  float: left;
  text-align: center;
}

.person .introduction img {
  display: inline-block;
  margin: auto;
  /*position: relative;*/
  float: none;
}

.person .introduction p {
  display: inline-block;
  margin: auto;
  /*position: relative;*/
  float: none;
  font-weight: 700;
  max-width: 60%;
}

p {
  font-size: 14px;
  color: #666666;
}

.person img {
  width: 20%;
  float: left;
}

button {
  width: 100%;
  height: 45px;
  border: 1px #dfdfdf solid;
  background: #f8f8f8;
  border-radius: 4px;
}

.card {
  background: #f8f8f8;
  padding: 30px 18px;
  margin-bottom: 10px;
  border: 1px solid #dfdfdf;
}

.card ul {
  list-style: none;
  padding: 0
}

.card ul li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 0;
  border-bottom: 1px solid #dfdfdf;
}

.card ul li img {
  width: 20%;
  float: left;
}

.card ul li p {
  width: 70%;
  float: left;
  line-height: 40px;
  margin: auto 0;
}

span {
  color: #333;
  font-weight: 600;
}

.card a {
  display: block;
  width: 100%;
  color: #fc4a1a;
  font-size: 14px;
  text-align: center;
}

.search {
  width: 100%;
  height: 40px;
  /*position: relative;*/
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

</style>
